@import '../../../../assets/styles/variables';
@import '../ForgotPassword/ForgotPassword.scss';
@import '../../LoginOption.scss';

.reset-client-page {
	@extend .forgot-page;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	font-family: 'Roboto', sans-serif;

	figure {
		text-align: center;

		img {
			text-align: center;
			width: 200px;
		}
	}

	&__back-btn {
		position: absolute;
		top: 20px;
		left: 20px;
		color: #fff;
		text-decoration: none;
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 8px;
		&:hover {
            color: #fff;
			text-decoration: none;
		}
		i {
			font-size: 16px;
		}
	}

	&__form {
		@extend .forgot-page__form;

		&__password-container {
			margin-left: 4.6rem;
			position: relative;
			width: 100%;
			
			i {
				position: absolute;
				right: 20%;
				top: 23%;
				color: rgba(255, 255, 255, 0.7);
				cursor: pointer;
				font-size: 18px;
				&:hover {
					color: rgba(255, 255, 255, 0.9);
				}
			}
		}

		p {
			font-size: 12px;
			font-weight: 400;
		}
		&__header {
			text-align: center;
		}

		&__body {
			@extend .forgot-page__form__body;
		}

		&__icon {
			@extend .forgot-page__form__icon;
			&--password {
				background-image: url('../../../../assets/imgs/noun_Lock-01.svg');
			}
			&[type='number'] {
				color: #b7b7b7;
				text-align: center;
				padding: 0;
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}

		&__text {
			&-list-header {
				align-self: flex-start;
				margin-left: 2.5rem;
			}
			&-list {
				list-style: disc;

				li {
					margin-bottom: 3px;
					font-size: 12px;
				}
			}
		}

		&__resend {
			text-align: center;

			&-link {
				font-family: $connect-font;
				color: #ffff;
				cursor: pointer;
				font-size: 14px;
				text-decoration: none;
				font-weight: 700;
				line-height: 16px;

				&.disabled {
					color: #282828;
					cursor: not-allowed;
					pointer-events: none;
				}

				&:hover {
					color: #f87000;
					text-decoration: none;
					opacity: 0.8;
				}
			}
		}

		&__countdown {
			margin: 0 !important;
			color: #f87000;
			font-size: 14px;
			font-weight: 700;
			margin-left: 5px;
		}
		&__footer {
			width: 90%;
			text-align: center;
			margin-top: 30px;
		}
		&__btn {
			@extend .login-form__btn;
			&--loading {
				@extend .login-form__btn--loading;
			}
		}
	}
}
