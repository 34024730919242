@import '../../assets/styles/variables';

.login-option {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;

	&__back {
		position: absolute;
		top: 20px;
		left: 20px;

		a {
			display: flex;
			align-items: center;
			gap: 8px;
			color: #ffffff;
			text-decoration: none;
			font-size: 14px;

			&:hover {
				color: #f87000;
			}

			i {
				font-size: 16px;
			}
		}
	}
	figure {
		text-align: center;

		img {
			text-align: center;
			width: 200px;
		}
	}
}

.login-form {
	max-width: 500px;
	margin: 50px auto;
	width: 350px;
	background-color: rgb(51, 51, 51);
	border-radius: 3px;
	padding: 30px 0 50px 0;

	&__header {
		text-align: center;

		span {
			font-size: 12px;
		}
	}
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 30px;

		input:not([type='checkbox']) {
			background-color: #4a4a4a;
			margin-bottom: 20px;
			height: 45px;
			border-radius: 3px;
			border: none;
			padding-left: 35px;
			padding-top: 5px;
			width: 85%;
			color: white;
			font-size: 16px;

			::placeholder {
				color: #4a4a4a !important;
			}
		}
	}

	&__icon {
		background-position: 4px 12px;
		background-repeat: no-repeat;
		background-size: 25px 20px;

		&--email {
			background-image: url('../../assets/imgs/noun_Email.svg');
		}

		&--password {
			background-image: url('../../assets/imgs/noun_Lock-01.svg');
		}
	}

	&__forgot-password {
		display: flex;
		justify-content: space-between;
		width: 85%;
		font-size: 12px;

		a {
			color: #fff;
			text-decoration: none;
		}
	}

	&__footer {
		width: 90%;
		text-align: center;
		margin-top: 30px;
	}

	&__btn {
		margin: 0 auto;
		padding: 16px/1.5 0;
		background: #f87000;
		width: 70%;
		height: 40px;
		border-radius: 3px;
		border: 1px solid #f87000;
		color: #ffffff;
		font-size: 16px;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		align-self: center;

		&--loading {
			margin: 0 auto;
			width: 20px;
			height: 20px;
			border: 4px solid #eee;
			border-right-color: transparent;
			border-radius: 50%;
			animation: loader-rotate 1s linear infinite;

			@keyframes loader-rotate {
				0% {
					transform: rotate(0);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}
