.billing-preorder-attachments {
	margin-top: 20px;
	&__header {
		display: flex;
		margin-top: inherit;
		justify-content: space-between;
	}

	&__list {
		width: 100%;
		overflow-y: auto;
		overflow-x: auto;
	}

	&__xml-errors-list {
		color: red;
		margin-top: 5px;
		max-height: 150px;
		overflow-y: auto;
	}
}
