@import '../../../assets/styles/variables';

.button-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.custom-button {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		border: 1px solid $primary;
		color: $primary;
		padding: 0 24px;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;
		border-radius: 30px;
		height: 40px;
		min-width: 183px;
		position: relative;
		width: auto;
		white-space: nowrap;

		.btn-loader {
			position: absolute;
			top: calc(50% - 8px);
			left: calc(50% - 8px);
			border: 4px solid white;
			border-right-color: transparent;
		}

		span {
			display: block;
		}

		&.primary {
			background-color: $primary;
			color: white;
			border: 1px solid transparent;

			&:disabled {
				background-color: #e5e5e5;
				color: white;
				cursor: not-allowed;
			}
		}

		&.outlined {
			background-color: transparent;
			color: $primary;
			border: 1px solid $primary;
		}

		&.visibility-hidden {
			display: none;
		}
	}

	.tooltip-button {
		position: absolute;
		bottom: -40px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #f2f2f2;
		color: #001d3d;
		padding: 0 8px;
		border-radius: 4px;
		font-size: 12px;
		white-space: nowrap;
		pointer-events: none;
		transition: opacity 0.3s ease-in-out;
		height: 32px;
		opacity: 0;
		margin-top: 2px;
		z-index: 100;
		display: flex;
		align-items: center;
	}

	&:hover {
		.tooltip-button {
			opacity: 1;
		}
	}

	&:disabled {
		.tooltip-button {
			display: none;
		}
	}
}
