@import '../../assets/styles/variables';

.navbar {
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1030;
	width: 100%;
	position: fixed;
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	&__search-input {
		color: black;
		width: 50%;
		display: flex;
		align-items: center;

		input {
			padding-left: 10px;
			background-color: white;
			border: 1px solid white;
			border-radius: 0;
			height: 40px;
			width: 45%;

			&::placeholder {
				color: $dark;
			}

			&:focus {
				background-color: white;
				border-radius: 0;
				border: 1px solid white;
			}
		}
	}

	&__drivers {
		border-radius: 18px;
		padding: 4px 0px;
		width: 100%;
		background-color: #ff5100;
		color: #181818;
		max-width: 180px;
		height: 30px;
		display: flex;
		justify-content: center;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			color: #181818;
		}
	}

	&__links {
		display: flex;
		align-items: center;
		margin-right: 50px;

		img {
			margin-top: 1px;
			height: 35px;
		}

		a {
			margin-right: 15px;
			background: transparent;
		}

		button {
			background: transparent;
			border: transparent;
		}
	}

	&__btn {
		height: 40px;
		width: 40px;
		border-radius: 0;
		box-shadow: none;
		background-color: $primary;
		border: $primary;

		.fa {
			font-size: 12px;
			margin-bottom: 2px;
			color: white;
		}
	}
}
