@import '../../../assets/styles/variables';

.alert-tag {

	&-container {
		display: flex;
		justify-content: center;
	}

	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	height: 18px;
	padding: 5px 6px;
	border-radius: 100px;
	gap: 2px;
	width: 53px;

	.warning {
		background-color: #fff8ea;
	}

	.icon {
		width: 8px;
		height: 8px;
	}

	.text {
		color: #001D3D;
		font-weight: 300;
		font-size: 12px;
		line-height: 16px
	}
}

.alert-tag.success {
	background-color: #ecf8e8;

	.icon {
		width: 9px;
		height: 9px;
	}
}

.alert-tag.warning {
	background-color: #fff8ea;
}
