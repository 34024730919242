@mixin respond($breakpoint) {
	@if $breakpoint == tab-portrait {
		@media only screen and (min-width: 600px) {
			@content;
		}
	}

	@if $breakpoint == tab-landscape {
		@media only screen and (min-width: 900px) {
			@content;
		}
	}

	@if $breakpoint == laptop {
		@media only screen and (min-width: 1024px) {
			@content;
		}
	}

	@if $breakpoint == desktop {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}
}
