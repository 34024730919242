.quoteItemWrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr repeat(3, minmax(auto, 130px)) 32px;
	gap: 1rem;
}

.formQuoteWrapper {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 1.5rem;
}

.quoteItemListWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}

.modalWrapper {
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	padding: 1.5rem;
	min-width: 848px;
}

.deleteButton {
	padding: 0.25rem;
	color: #f15b2b;
}

.hideDeleteButton {
	visibility: hidden;
	pointer-events: none;
}

.totalQuote {
	grid-column: 4 / 5; /* Stay in the middle column */
}

.quoteName > p {
	margin-bottom: 1rem;
	color: #586983 !important;
}
