@font-face {
	font-family: 'Connect';
	font-weight: 100;
	font-style: italic;
	src: url('../../../public/fonts/Connect-ThinItalic.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Connect';
	font-weight: 100;
	font-style: normal;
	src: url('../../../public/fonts/Connect-Thin.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 200;
	font-style: italic;
	src: url('../../../public/fonts/Connect-LightItalic.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 200;
	font-style: normal;
	src: url('../../../public/fonts/Connect-Light.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 400;
	font-style: italic;
	src: url('../../../public/fonts/Connect-RegularItalic.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 400;
	font-style: normal;
	src: url('../../../public/fonts/Connect-Regular.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 700;
	font-style: italic;
	src: url('../../../public/fonts/Connect-BoldItalic.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 700;
	font-style: normal;
	src: url('../../../public/fonts/Connect-Bold.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 900;
	font-style: italic;
	src: url('../../../public/fonts/Connect-BlackItalic.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Connect';
	font-weight: 900;
	font-style: normal;
	src: url('../../../public/fonts/Connect-Black.woff2') format('woff2');
	font-display: swap;
}
