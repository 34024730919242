@use '../../assets/styles/variables';

.reject-invoice-modal {
	&__form {
		margin: 1rem 0.5rem 0.5rem 0.5rem;
	}
	&__reason-textarea {
		margin-bottom: 1.5rem;
	}
	&__label {
		margin-bottom: 1rem;
		font-size: variables.$font-size-medium;
	}
	&__textarea {
		resize: none;
		border-radius: variables.$border-radius;
		width: 35rem;
	}
	&__btns {
		display: flex;
		justify-content: flex-end;
	}
	&__btn {
		padding: 0 1rem;
		height: variables.$btn-height;
	}
	&__btn:nth-child(2) {
		margin-left: 1rem;
	}
	&__reject-invoice-btn {
		background-color: variables.$btn-danger;
		color: variables.$font-white;
		border-radius: variables.$btn-border-radius;
		min-width: 180px;
		&:hover {
			background-color: variables.$btn-danger-hover;
		}
	}
	&__cancel-btn {
		border-color: variables.$btn-gray;
		color: variables.$font-black;
		border-radius: variables.$btn-border-radius;
		background-color: variables.$btn-secondary-action;
	}
}
