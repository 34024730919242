.quoteDetails {
	padding: 0.75rem 1rem;
	background-color: #fff;
	border: #cadae8 solid 1px;
}

.quoteHeader {
	display: flex;
	justify-content: space-between;
}

.quoteNote {
	margin-top: 0.5rem;
}

.quoteDescription {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.quoteNote span:first-child {
	margin-right: 4px;
}

.quoteItems {
	display: grid;
	grid-template-columns: auto 40px minmax(88px, min-content) minmax(88px, min-content);
	column-gap: 0.5rem;
	row-gap: 0.5rem;
	justify-items: center;
	margin-bottom: 1rem;
}

.quoteItemsHeader {
	color: #586983;
	font-family: Connect;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5rem;
	letter-spacing: 0.8px;
}

.quoteTotal {
	color: #586983;
	font-family: Connect;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5rem;
	letter-spacing: 0.8px;
	text-align: end;
}

.quoteItemElement {
	color: #001d3d;
	font-family: Connect;
	font-size: 1rem;
	font-style: normal;
	font-weight: 200;
	line-height: 1.5rem;
	letter-spacing: 0.8px;
}

.quoteItemDescription {
	justify-self: start; /* Justify the items in the first column to the start */
}
