
.login-form {
	&__password-container {
		margin-left: 3.2rem;
		position: relative;
		width: 100%;
		i {
			position: absolute;
			right: 20%;
			top: 23%;
			color: rgba(255, 255, 255, 0.7);
			cursor: pointer;
			font-size: 18px;
			&:hover {
				color: rgba(255, 255, 255, 0.9);
			}
		}
	}
}


.admin-header {
	font-family: 'Roboto', sans-serif;
	padding: 0 24px;

	h4 {
		font-size: 24px;
		margin-bottom: 16px;
		color: #fff;
	}
}

.admin-subheader {
	color: #fff;
	font-size: 14px;
	line-height: 1.5;
	margin-bottom: 24px;
}

.admin-highlight {
	color: #f87000;
}

.admin-google-btn {
	width: 85%;
	margin: 0 auto;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	border: 1px solid #8E918F;
	border-radius: 50px;
	background: #131314;
	cursor: pointer;
	color: white;
	font-size: 16px;
	transition: background-color 0.2s ease;

	&:hover {
		background: #0d0d0e;
	}

	img {
		width: 20px;
		height: 20px;
	}
}
