@media screen and (max-width: 600px) {
	.dashboard {
		&__header {
			h1 {
				font-size: 60px;
			}
			flex-direction: column;
		}
		&__status-filters {
			img {
				height: 50px;
				cursor: pointer;
			}
		}
		&__search {
			margin-top: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 15px;
		}
		&__filters {
			flex-direction: column;
			gap: 15px;
		}
		&__date-input {
			height: 40px;
		}
	}
}
@media screen and (min-width: 601px) {
	.dashboard {
		&__header {
			h1 {
				font-size: 90px;
			}
		}
		&__status-filters {
			img {
				height: 90px;
				margin-top: 10px;
				margin-right: 30px;
				cursor: pointer;
			}
		}
		&__search {
			margin-top: 32px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}
		&__filters {
			flex-direction: row;
		}
	}
}
.dashboard {
	display: flex;
	height: 100%;
	flex-direction: column;

	&__header {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 1.5rem;

		h1 {
			color: orange;
			font-weight: 300;
			font-family: Lato, sans-serif;
		}
	}

	&__btns {
		display: flex;
	}

	&__status-filters {
		display: flex;
	}

	&__filters {
		margin: 16px 16px 30px 20px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 20px;
		align-items: start;
	}

	&__pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;

		&__icon {
			font-size: 12px;
			color: #fff;
		}

		&__pages {
			color: #fff;
			font-size: 16px;
			padding: 4px 12px;

			&__main {
				padding: 4px 12px;
				text-decoration: underline;
				color: #ff5100;
			}
		}

		&__space {
			margin-bottom: 160px;
		}
	}

	&__date-input {
		background-color: #454545;
		border-radius: 16px;
		border: none;
		padding-left: 20px;
		min-width: 150px;
		color: #bbb;
		height: 43px;
	}

	&__download {
		text-align: center;
		text-decoration: none;
		color: #fff;
		width: 150px;
		height: 39px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__close-icon {
		border: 1px solid transparent;
		background-color: transparent;
		display: inline-block;
		vertical-align: middle;
		outline: 0;
		cursor: pointer;
	}

	&__close-icon:after {
		content: 'X';
		display: block;
		width: 20px;
		height: 20px;
		background-color: #ff5100;
		z-index: 1;
		right: 35px;
		top: 0;
		bottom: 0;
		margin: auto;
		padding: 2px;
		border-radius: 50%;
		text-align: center;
		color: white;
		font-weight: bold;
		font-size: 11px;
		box-shadow: 0 0 2px #e50f0f;
		cursor: pointer;
	}

	&__btn {
		display: block;
		border-radius: 16px;
		padding: 10px;
		font-size: 14px;
		margin-right: 10px;
		color: #fff;
		max-width: 200px;
		min-height: 39px;

		&--primary {
			background-color: #ff5100;
			border-color: #ff5100;
		}

		&--danger {
			background-color: red;
			border-color: red;
		}

		&--loading {
			display: inline-block;
			min-height: 0px;
			padding: 5px;
			border: 4px solid #eee;
			border-right-color: transparent;
			border-radius: 50%;
			animation: loader-rotate 1s linear infinite;

			@keyframes loader-rotate {
				0% {
					transform: rotate(0);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
	&__cell-container {
		display: grid;
		gap: 0.5rem;
		width: 100%;
		height: 100%;
		padding: 0.5rem;
		border-radius: 5px;
		flex: 1;
	}

	&__cell-skeleton {
		height: 70px;
		margin-bottom: 4px;
	}
}

button,
html input[type='button'],
input[type='reset'] {
	border: 0;
	cursor: pointer;
	-webkit-appearance: button;
}
