@import '../../assets/styles/variables';

.login-page {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	width: 67%;
	margin: 0 auto;

	&__card {
		text-align: center;
		padding-top: 10px;
		border: 3px solid #000;
		width: 400px;
		margin: 15px;
		height: 200px;
		background-color: #353535;
		text-decoration: none;
		display: flex;
    flex-direction: column;
    align-items: center;

		&:hover {
			cursor: pointer;
			background-color: #6c757d;
		}

		h5 {
			color: $primary;
		}

		&-tow {
			height: 130px;
		}

		&-user {
			height: 90px;
			margin-top: 15px;
		}
	}
}
