@import '../../assets/styles/variables';

.action-buttons-container {
  display: flex;
  justify-content: space-between;

  &__btn {
    margin: 5px;
    width: 144px;
    height: 30px;
    border: 1px solid;
    border-radius: 13px;

    &__btn-cancel {
      @extend .btn-cancel;
    }

    &__btn-edit-confirm {
      @extend .btn-edit-confirm;
    }

    &__btn-edit {
      @extend .btn-edit;
    }

    &__btn-confirm {
      @extend .btn-confirm;
    }

    &__btn-finish {
      @extend .btn-finish;
    }
  }
}

.btn-cancel {
  border-color: $success;
  color: $success;
  background-color: transparent;

  &:hover {
    color: $font-black;
    background-color: $success;
  }
}

.btn-edit-confirm {
  border-color: $success;
  color: $font-black;
  background-color: $success;

  &:hover {
    color: $font-white;
    background-color: transparent;
    border-color: $light;
  }
}

.btn-edit {
  border-color: $light;
  color: $font-white;
  background-color: transparent;

  &:hover {
    color: $font-black;
    background-color: $success;
  }
}

.btn-confirm {
  border-color: $light;
  color: $font-black;
  background-color: $light;

  &:hover {
    color: $font-white;
    background-color: transparent;
    border-color: $light;
  }
}

.btn-finish {
  border-color: $status-finished;
  color: $font-white;
  background-color: $status-finished;

  &:hover {
    color: $font-white;
    background-color: transparent;
    border-color: $status-finished;
  }
}
