.map {
	flex: 1;
	height: 400px;

	#map {
		height: 100%;
		width: 100%;
	}

	.infoBox {
		width: unset;
		// these two must be applied to override google maps infBox style
		overflow: hidden !important;
		transform: translateX(-20px) !important;

		.info-box {
			margin-top: 13px;
			width: 40px;
			height: 40px;
			color: #fff;
			font-size: 12px;
			padding-top: 8px;
			text-transform: uppercase;
			border-radius: 50px;
			background-color: #ff6b36;
			text-align: center;

			&--new {
				background-color: #555555;
			}
			&--accepted {
				background-color: #3bda23;
			}
			&--on_route {
				background-color: #00c3b3;
			}
			&--arrived {
				background-color: #f8cd00;
			}
			&--towed {
				background-color: #6e5ca8;
			}
			&--arrived {
				background-color: #f8cd00;
			}

			&--active {
				background-color: #ff6b36;
			}
			&--busy {
				background-color: #555555;
			}
		}
	}
}
