@import '../../assets/styles/variables';

.billing-pre-order {
	display: flex;
	flex-direction: column;
	height: 100%;
	p {
		display: inline-block;
		margin: 10px;
	}

	&__form {
		flex: 1;
		display: flex;
		justify-content: space-between;
		max-height: calc(100% - 160px);
	}

	button {
		width: auto;
		display: inline;
	}

	.inline {
		display: inline-block;
		margin: 10px;
	}

	&__label {
		color: $dark;
	}

	&__statuses {
		&__btn {
			border-radius: 15px;
			padding-inline-start: 15px;
			padding-inline-end: 15px;
			background-color: $btn-secondary-action;
			color: $font-black;
		}

		&__btn-continue {
			background-color: $status-accepted;
			color: white;
			border-radius: 15px;
			padding-inline-start: 15px;
			padding-inline-end: 15px;
		}

		&__btn-upload-file {
			background-color: $primary;
			color: white;
			border-radius: 15px;
			padding-inline-start: 15px;
			padding-inline-end: 15px;
		}
	}

	.billing-pre-order-detail {
		color: black;
		//float: left;
		margin: 10px 0px 0px 10px;
		//height: 72vh;
		min-width: 500px;
		overflow-y: auto;

		&__table-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&__table {
				overflow-y: auto;
			}
		}

		.external-invoice-input {
			width: 400px;
			border: 0;
		}

		.file-element {
			justify-content: space-between;
			display: flex;
			width: 100%;
			background-color: #eee;
			padding: 5px;
			margin: 5px 0px 5px 0px;
			text-align: center;
			vertical-align: middle;
			line-height: 40px;
			button {
				padding-top: 0px;
			}
		}
	}

	.table {
		margin: 10px 0px 0px 0px;

		&__header {
			display: flex;
			justify-content: space-evenly;

			& h6 {
				display: flex;
				justify-content: center;
				align-items: center;
				width: calc(100% / 6);
			}
		}

		&__body {
			height: 94%;
			width: 100%;
			overflow-y: auto;
			overflow-x: auto;
		}

		&__row {
			display: flex;
			align-items: center;
			height: 100px;

			&:nth-child(odd) {
				background-color: #eee;
			}

			& span {
				width: calc(100% / 6);
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		&__footer {
			display: flex;
			justify-content: space-evenly;

			h6 {
				margin: 20px;
				text-align: center;
			}
		}
	}

	.download-actions {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
		margin: 20px;
	}
}

.footer {
	position: fixed;
	bottom: 15%;
}

ul {
	list-style-type: none;
}
