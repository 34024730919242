@import '../../assets/styles/variables';

@media screen and (max-width: 600px) {
	.table-head {
		display: table-header-group;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.table-row {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		margin: 0 0.5rem 1rem 0.5rem;
		background-color: #1f1f1f;
		border-radius: 15px;
	}
	.eos-table__icon {
		width: 50px;
		height: 50px;
	}
	.table-cell {
		display: block;
		text-align: right;
	}
	.table-cell::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
	}
	.status-cell {
		display: flex;
		justify-content: center;
		text-align: center;
	}
}
@media screen and (min-width: 601px) {
	.table-head {
		display: table-header-group;
	}

	.table-body {
		display: table-row-group;
	}

	.table-row {
		display: table-row;
	}

	.table-cell,
	.eos-table__header {
		display: table-cell;
		padding: 0.75rem;
		text-align: center;
	}

	.eos-table__header {
		border-style: hidden;
		font-size: 14px;
		color: white;
		background-color: #282828;

		&__column-w-15 {
			width: 15%;
		}

		&__column-w-7 {
			width: 7%;
		}
	}

	.table-row:nth-child(odd) {
		background-color: rgba(255, 255, 255, 0.05);
	}

	.eos-table {
		display: table;
		font-size: 12px;
		border: none;
		width: 98%;
		margin: 0 auto;
		border-spacing: 0;
		table-layout: fixed;

		&__tooltip {
			position: relative;
			display: inline-block;
		}

		&__tooltip &__tooltiptext {
			visibility: hidden;
			width: 120px;
			background-color: #555;
			color: #fff;
			text-align: center;
			border-radius: 6px;
			padding: 5px 0px;
			position: absolute;
			z-index: 1;
			bottom: 125%;
			left: 50%;
			margin-left: -60px;
			transition: opacity 0.3s;
		}

		&__tooltip &__tooltiptext::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: #555 transparent transparent transparent;
		}

		&__tooltip:hover &__tooltiptext {
			visibility: visible;
		}

		&__tooltip &__tooltipcheck {
			visibility: hidden;
			width: 235px;
			background-color: #f2f2f2;
			color: #000;
			text-align: center;
			border-radius: 0px;
			padding: 5px 0px;
			position: absolute;
			z-index: 1;
			bottom: 0%;
			left: 100%;
			margin-left: 15px;
			transition: opacity 0.3s;
			margin-bottom: -6px;
		}

		&__tooltip &__tooltipcheck {
			visibility: hidden;
			width: 235px;
			background-color: #f2f2f2;
			color: #000;
			text-align: center;
			border-radius: 0px;
			padding: 5px 0px;
			position: absolute;
			z-index: 1;
			bottom: 0%;
			left: 100%;
			margin-left: 15px;
			transition: opacity 0.3s;
			margin-bottom: -6px;
		}

		&__tooltip &__tooltipcheck::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: #555 transparent transparent transparent;
		}

		&__tooltip:hover &__tooltipcheck {
			visibility: visible;
		}

		&--hover {
			.table-row:hover {
				color: $primary;
				cursor: pointer;
			}
		}

		&__icon {
			height: 35px;
			width: auto;

			img {
				vertical-align: middle;
			}
		}

		&__chat-column {
			width: 60px;
		}

		&__chat-bubble {
			height: 25px;
		}

		&__chat-alert {
			position: relative;
			top: -28px;
			left: 40px;
			border: 1px solid red;
			border-radius: 50%;
			width: 12px;
			height: 12px;
			background-color: red;
		}

		&__btn {
			display: flex;
			border-radius: 8px;
			padding: 8px;
			width: 120%;
			background-color: #58595b;
			color: white;
			height: 30px;
			align-items: center;
			justify-content: center;

			&.new {
				background-color: $status-new;
				border-color: $status-new;
			}

			&.accepted {
				background-color: $status-accepted;
				border-color: $status-accepted;
			}

			&.on-route {
				background-color: $status-on-route;
				border-color: $status-on-route;
			}
			&.arrived {
				background-color: $status-arrived;
				border-color: $status-arrived;
			}
			&.towed {
				background-color: $status-towed;
				border-color: $status-towed;
			}
			&.finished {
				background-color: $status-finished;
				border-color: $status-finished;
			}
			&.declined {
				background-color: $status-declined;
				border-color: $status-declined;
			}
			&.cancelled-by-driver {
				background-color: $status-declined;
				border-color: $status-declined;
			}
			&.pending-audit {
				background-color: $status-pending;
				border-color: $status-pending;
			}

			&.pending {
				background-color: $invoice-pending;
				border-color: $invoice-pending;
			}

			&.rejected {
				background-color: $invoice-rejected;
				border-color: $invoice-rejected;
			}

			&.validated {
				background-color: $invoice-validated;
				border-color: $invoice-validated;
			}

			&.topayment {
				background-color: $invoice-payment;
				border-color: $invoice-payment;
			}

			&--lg {
				height: 52px;
			}
		}

		&__rejectServiceButton {
			border: none;
			background: none;
			cursor: pointer;
			margin: auto;
		}

		&__rejectTD {
			border: none;
			padding: 0 !important;
		}
	}
}

// react-toggle styles
.react-toggle {
	touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4d4d4d;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
	background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #128d15;
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4d4d4d;
	border-radius: 50%;
	background-color: #fafafa;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 3px 2px #0099e0;
	-moz-box-shadow: 0px 0px 3px 2px #0099e0;
	box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 5px 5px #0099e0;
	-moz-box-shadow: 0px 0px 5px 5px #0099e0;
	box-shadow: 0px 0px 5px 5px #0099e0;
}

.rejectIcon {
	fill: #dc3545;
	width: 25px;
	height: 25px;
	transition: fill 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rejectIcon:hover {
	fill: #bb2d3b;
}

.table-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #343334;
	height: 48px;
	padding-right: 32px;
	padding-left: 24px;
	margin-bottom: 12px;
}

.cursor-no-drop {
	cursor: no-drop
}
