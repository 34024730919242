@import '../../assets/styles/variables';

@media screen and (max-width: 600px){
	.h-filter-container {
		width: 250px;
		margin-left: 0;
	}
}

@media screen and (min-width: 601px){
	.h-filter-container {
		width: 250px;
		margin-right: 16px;
		margin-left: 20px;
	}
}
.h-filter-container {
	position: relative;

	.filter {
		width: inherit;
		background-color: $gray;
		border: 2px solid $gray;
		padding: 10px 16px 8px 16px;
		box-shadow: none;
		vertical-align: middle;
		border-radius: 16px;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
	}

	.filter-dropdown {
		width: inherit;
		background-color: $gray;
		border: 2px solid $gray;
		padding: 10px 16px 8px 16px;
		box-shadow: none;
		vertical-align: middle;
		border-radius: 16px;
		font-size: 14px;
		margin-top: 10px;
		border-radius: 16px;

		max-height: 300px;
		overflow-y: auto;
		position: absolute;
		z-index: 9999;
		-webkit-box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.47);
		-moz-box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.47);
		box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.47);

		&__item {
			cursor: pointer;
			padding: 12px 16px 12px 16px;
			border: none;

			&:hover {
				background-color: rgba($color: $charcoal-gray, $alpha: 0.6);
			}
		}
	}
}
