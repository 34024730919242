@use './assets/styles/variables' as connectVars;
// Customize @carbon/react theme overwriting theme tokens here
@use '@carbon/react/scss/theme' with
  (
    $theme: (
      text-error: connectVars.$connect-colors-alertas-error,
      support-error: connectVars.$connect-colors-alertas-error,
      interactive: connectVars.$connect-colors-naranja-02,
      link-primary: connectVars.$connect-colors-texto-naranja-secundario,
      link-primary-hover: connectVars.$connect-colors-naranja-01,
      focus: transparent,
    )
  );
// button, notification and tag components have specific tokens,
// this requires a specific overwrite, use the following lines as example
@use '@carbon/react/scss/components/button/tokens' as button-tokens with (
  // primary
  $button-primary: connectVars.$connect-colors-naranja-02,
  $button-primary-hover: connectVars.$connect-colors-naranja-03,
  $button-primary-active: connectVars.$connect-colors-naranja-01,
  // tertiary
  $button-tertiary: connectVars.$connect-colors-naranja-02,
  $button-tertiary-hover: connectVars.$connect-colors-naranja-02,
  $button-tertiary-active: connectVars.$connect-colors-naranja-01,
);
// Import the @carbon/react styles
@use '@carbon/react';

// Additional customizations =============================================
// =
// Load connect font
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap');
// =
// Button component
.cds--btn {
	border-radius: theme.get('spacing-03');
	padding: 10px 20px;
	width: unset;
	max-width: unset;
	align-items: center;
	justify-content: center;

	//styleName: General/Button Medium;
	font-family: Inter;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.5px;
}
// Loading component
.cds--loading--small {
	width: unset;
	height: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	& > .cds--loading__svg {
		width: 1rem;
		& > .cds--loading__background {
			r: 40;
		}
		& > .cds--loading__stroke {
			r: 40;
			stroke: connectVars.$connect-colors-naranja-01;
			stroke-dashoffset: 154.76;
		}
	}
}
