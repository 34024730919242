@import '../../assets/styles/variables';

.m-filter-container {
	display: flex;
	flex-wrap: wrap-reverse;
	width: 250px;
	position: relative;
	flex-direction: column;

	&__input {
		background: transparent;
		border: none;
		color: white;
		outline: none;
		width: 90%;
	}

	&__filter {
		min-width: 250px;
		background-color: $gray;
		border: 2px solid $gray;
		padding: 10px 16px 8px 16px;
		box-shadow: none;
		vertical-align: middle;
		border-radius: 16px;
		font-size: 14px;

		.selection {
			color: white;
			padding: 8px;
			margin: 0 2px;
			border-radius: 16px;
			background-color: $charcoal-gray;
		}
	}

	&__icon {
		float: right;
	}

	&__dropdown {
		width: inherit;
		background-color: $gray;
		border: 2px solid $gray;
		padding: 10px 16px 8px 16px;
		box-shadow: none;
		vertical-align: middle;
		border-radius: 16px;
		font-size: 14px;
		margin-top: 55px;
		border-radius: 16px;

		max-height: 300px;
		overflow-y: auto;
		position: absolute;
		z-index: 9999;
		-webkit-box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.47);
		-moz-box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.47);
		box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.47);

		&__item {
			cursor: pointer;
			padding: 12px 16px 12px 16px;
			border: none;

			&:hover {
				background-color: rgba($color: $charcoal-gray, $alpha: 0.6);
			}
		}
	}

	.checkbox {
		display: inline-flex;
		cursor: pointer;
		position: relative;
	}

	.checkbox > input {
		margin-right: 6px;
		height: 18px;
		width: 18px;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		border: 2px solid $light-gray;
		border-radius: 4px;
		outline: none;
		transition-duration: 0.3s;
		background-color: transparent;
		cursor: pointer;
	}

	.checkbox > input:checked {
		border: 2px solid $primary;
		background-color: transparent;
	}

	.checkbox > input:checked + span::before {
		content: '\2713';
		display: block;
		text-align: center;
		color: $primary;
		position: absolute;
		left: 0.2rem;
		top: 0rem;
		pointer-events: none;
	}

	.checkbox > input:active {
		border: 2px solid #34495e;
	}

	/* width */
	::-webkit-scrollbar {
		width: 16px;
		background-color: transparent;
		left: -100px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: transparent;
		border: 1px solid $light-gray;
		border-radius: 16px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: $light-gray;
		border-radius: 16px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
