@import '../../assets/styles/variables';

.map-view {
	flex: 1;
	height: 96vh;

	#map {
		height: 100%;
		width: 100%;
	}

	.driver-info {
		z-index: 1000;
		position: absolute;
		top: 100px;
		left: 65%;
		max-width: 500px;
		width: 500px;
		max-height: calc(100vh - 230px);
		overflow-y: scroll;
		background: black;
		border-radius: 0 0 30px 0;
		height: auto;

		&__header {
			font-size: 14px;
			display: flex;
			justify-content: space-between;
			padding: 10px;
			height: 40px;

			span {
				cursor: pointer;
			}
		}

		&__body {
			background-color: $gray;
			border-radius: 0 0 30px 0;
			padding: 15px;
		}

		&__driver-name {
			color: #fd5a00;
		}
	}

	.infoBox {
		width: unset;
		// these two must be applied to override google maps infBox style
		overflow: hidden !important;
		transform: translateX(-20px) !important;

		.info-box {
			width: 40px;
			height: 40px;
			color: #fff;
			font-size: 12px;
			padding-top: 8px;
			text-transform: uppercase;
			border-radius: 50px;
			background-color: #ff6b36;
			text-align: center;

			&--active {
				background-color: #ff6b36;
			}

			&--new {
				background-color: #555555;
			}
			&--accepted {
				background-color: #3bda23;
			}
			&--on_route {
				background-color: #00c3b3;
			}
			&--arrived {
				background-color: #f8cd00;
			}
			&--towed {
				background-color: #6e5ca8;
			}
			&--busy {
				background-color: #555555;
			}
		}
	}
}

// Using important to override inline-style from
// google maps div.... :(
#map div.gm-style-moc {
	z-index: 0 !important;
}
