.accordionRoot {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-height: 500px;
	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-gutter: stable;
	scrollbar-color: #c5c5c5 white;
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.quoteNote {
	background-color: #f8fcff;
	border: 1px solid #eef7ff;
	padding: 1rem;
	margin-bottom: 1.5rem;
	font-size: 1rem;
	letter-spacing: 0.8px;
	color: #001d3d;
	font-weight: 200;
}

.quoteNoteAuthor {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}
