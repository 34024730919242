//Global Styles
$border-radius: 0.25rem;

//Font Styles
$font-white: #ffff;
$font-black: #282828;
$font-size-small: 1rem;
$font-size-medium: 1.3rem;
$font-size-lg: 1.5rem;

//Buttons Styles
$btn-border-radius: 1rem;
$btn-danger: #FF4B4B;
$btn-danger-hover: #ff5757;
$btn-success: #43A047;
$btn-success-hover: #43A047;
$btn-secondary-action: #efefef;
$btn-secondary-action-hover: #f5f5f5;
$btn-gray: #353535;
$btn-height: 3rem;

$primary: #F15B2B;
$primary-light: #F15B2B;
$secondary: #58595b;
$success: #43A047;
$danger: #FF4B4B;
$info: #00c4b3;
$warning: #f8cd00;
$light: #e8e8e8;
$dark: #282828;
$dark-gray: #181818;
$charcoal-gray: #292829;
$gray: #353535;
$light-gray: #939393;

$status-new: #454545;
$status-on-route: #50b2b9;
$status-accepted: #108043;
$status-arrived: #d79c02;
$status-towed: #6e5ca8;
$status-finished: #009ce4;
$status-declined: #bf0711;
$status-pending: #6e5ca8;

// INVOICE STATUS COLORS
$invoice-pending: #50b2b9;
$invoice-rejected: $danger;
$invoice-validated: #108043;
$invoice-payment: #4b2188;

$padding: 16px;

$app-font: 'Trade Gothic', Helvetica, Arial, sans-serif;
$app-font-bold: 'Trade Gothic Bold';
$app-font-light: 'Trade Gothic Light';

// Connect Design System vars
$connect-font: 'Inter', sans-serif;
$connect-colors-white: #FFF;
$connect-colors-naranja-connect: #F87000;
$connect-colors-naranja-01: #A84526;
$connect-colors-naranja-02: #DD620D;
$connect-colors-naranja-03: #FA9D51;
$connect-colors-naranja-04: #FDCBA2;
$connect-colors-naranja-05: #FFF3EA;
$connect-colors-azul-02: #4799B4;
$connect-colors-alertas-error: #F44336;
$connect-colors-texto-naranja-secundario: #DD620D;
