@import '../../assets/styles/variables';

button,
html input[type='button'],
input[type='reset'] {
	border: 0;
	cursor: pointer;
	-webkit-appearance: button;
}

.search-container {
	display: flex;

	&__input {
		background-color: #454545;
		border-color: #454545;
		border-radius: 16px;
		margin-right: 10px;
		color: white;
		border: none;
		margin-left: 16px;
		padding-left: 20px;
	}

	&__input::placeholder {
		color: #bbb;
	}

	&__btn {
		border-radius: 16px;
		padding-left: 26px;
		padding-right: 26px;
		font-size: 14px;
		margin-right: 10px;
		color: #fff;
		width: 100px;

		&--primary {
			background-color: #ff5100;
			border-color: #ff5100;
		}

		&--danger {
			background-color: red;
			border-color: red;
		}
	}
}
