@import '../../assets/styles/variables';

.btn-group {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    button {
        width: auto;
        display: inline;
    }
    &__btn {
        background-color: $primary;
        color: white;
        border-radius: 15px;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
        &:hover {
			color: $font-black;
		}
    }
}