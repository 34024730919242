@use '../../assets/styles/variables';

.invoice {
	display: flex;
	justify-content: space-between;
	color: variables.$font-black;
	font-size: 1rem;
	height: 100%;
	padding: 0 1rem 1rem 1rem;
	flex-direction: column;

	.invoice-header {
		display: flex;
		justify-content: space-between;
		height: 4rem;
		align-items: center;
		width: 100%;
		&__left-container {
			display: flex;
		}
		&__go-back-btn {
			display: flex;
			align-items: center;
			width: auto;
			padding: 0;
			background: transparent;
			fill: variables.$font-black;
			transform: perspective(1px) translateZ(0);
			transition-duration: .2s;
			transition-property: color, background-color, fill;
			& p {
				margin-bottom: 0 !important;
			}
			&:hover {
				color: variables.$primary;
				fill: variables.$primary;
			}
		}
		&__send-email-btn {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 0.5rem;
			background: transparent;
			width: auto;
			padding: 0 1rem;
			border: solid 1px #353535;
			fill: variables.$font-black;
			transform: perspective(1px) translateZ(0);
			transition-duration: .3s;
			transition-property: background-color;
			& p {
				margin: 0 0 0 5px !important;
			}
			&:hover {
				background-color: variables.$btn-secondary-action-hover;
			}
		}
		&__actions {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
	}
	.send-email-svg {
		width: 25px;
		height: 25px;
	}

	.go-back-svg {
		width: 35px;
		height: 35px;
	}

	.header-body-division {
		margin: 0 0 2rem 0rem;
		opacity: .25;
	}

	.invoice-details {
		display: flex;
	}

	.invoice-numbers {
		display: flex;
		align-items: center;
		margin-left: 2rem;
		font-size: variables.$font-size-lg;
		p {
			margin-bottom: 0 !important;
		}
		p:last-child {
			margin-left: 1.5rem;
		}
	}

	.invoice-badges {
		display: flex;
		align-items: center;
		margin-left: 3rem;
	}
	.invoice-badge {
		border-radius: 2rem;
    	height: fit-content;
    	padding: 0.3rem 1.5rem;
		&:nth-child(2) {
			margin-left: 1.5rem;
		}
		&__amount-services {
			background-color: variables.$btn-secondary-action;
		}
		&__invoice-status {
			border: transparent;
		}
	}

	.invoice-body {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.rejected-details {
		width: 100%;
		height: fit-content;
		margin-right: 1rem;
		&__text {
			background-color: #F1F4F7;
			padding: 1rem;
			border-radius: 0.5rem;
		}
		h6 {
			margin-left: 2rem;
		}
	}

	.invoice-table {
    	width: 100%;

		&__header {
			display: flex;
			justify-content: space-evenly;

			& h6 {
				display: flex;
				justify-content: center;
				align-items: center;
				width: calc(100% / 6);
			}
		}

		&__body {
			height: 150px;
			overflow: auto;
		}

		&__row {
			display: flex;
			align-items: center;
			border-radius: .5rem;
			margin-right: .5rem;

			&:nth-child(odd) {
				background-color: #F1F4F7;
			}

			& span {
				width: calc(100% / 6);
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					padding: 4px;
				}
			}
		}

		&__footer {
			display: flex;
			justify-content: flex-end;

			h6 {
				margin: 20px;
				text-align: center;
			}
		}
	}

	.invoice-footer {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 25px;

		&__btns {
			display: flex;
			justify-content: flex-start;
		}

		&__btn {
			margin-left: 1rem;
			width: 10rem;
			height: 3rem;
			border-radius: 1rem;
		}

		&__approve-btn {
			background-color: variables.$btn-success;
			color: #fff;
			&:hover {
				background-color: variables.$btn-success-hover;
			}
		}

		&__reject-btn {
			border: 1px solid variables.$btn-danger;
			background-color: variables.$font-white;
			color: variables.$btn-danger;

			transform: perspective(1px) translateZ(0);
			transition-duration: 0.3s;
			transition-property: color, background-color;
			&:hover {
				background-color: variables.$btn-danger;
    			color: variables.$font-white;
			}
		}

		&__amounts {
			font-size: 16px;
			td {
				font-weight: bold;
				padding-right: 15px;
			}

			tr {
				td:nth-child(2) {
					text-align: right;
				}
			}

			&-total {
				td {
					padding-top: 10px;
				}
			}

		}
	}

	&__scrollbar::-webkit-scrollbar {
		width: 10px;
	}

	&__scrollbar::-webkit-scrollbar-track {
		cursor: pointer;
		background-color: rgb(184, 184, 184);
	}

	&__scrollbar::-webkit-scrollbar-thumb {
		background-color: #696868;
	}

	.pending {
		background-color: variables.$invoice-pending;
		border-color: variables.$invoice-pending;
		color: #fff;
	}

	.rejected {
		background-color: variables.$invoice-rejected;
		color: #fff;
	}

	.validated {
		background-color: variables.$invoice-validated;
		border-color: variables.$invoice-validated;
		color: #fff;
	}

	.payment {
		background-color: variables.$invoice-payment;
		border-color: variables.$invoice-payment;
		color: #fff;
	}
}

.spinner-container {
	display: flex;
	justify-content: center;
}
