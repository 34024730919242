@use "../../assets/styles/variables";

.confirm-invoice-action-modal {
	margin: 0.5rem 1.5rem;
	&__text {
		font-size: 1.3rem;
		margin-bottom: 2rem;
	}
	&__btns {
		display: flex;
		justify-content: flex-end;
	}
	&__btn {
		padding: 0 1rem;
		height: variables.$btn-height;
		border-radius: variables.$btn-border-radius;
		&:nth-child(2) {
			margin-left: 1rem;
		}
	}
	&__approve-invoice-btn {
		background-color: variables.$btn-success;
		color: #fff;
		&:hover {
			background-color: variables.$btn-success-hover;
		}
	}
	&__cancel-btn {
		background-color: variables.$btn-secondary-action;
		color: variables.$font-black;
		&:hover {
			background-color: variables.$btn-secondary-action-hover;
		}
	}

	button {
		min-width: 180px;
	}
}
