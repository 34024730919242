.drawer {
    margin: 0;
    position: fixed;
    bottom: 0;
	height: 90vh;
	background: white;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	position: fixed;
	right: 0;
	z-index: 200;
	transition: transform 0.5s ease-out;
	&__window {
		height: 100%;
	}
	&__container {
		height: 100%;
		overflow-y: scroll;
	}
	&--md {
		width: 100%;
	}

	&--lg {
		width: 100%;
	}

	&__up {
		transform: translateY(100%);

		&--open {
			transform: translateY(0);
		}
	}

	&__up__85 {
		transform: translateY(100%);

		&--open {
			transform: translateY(74%);
		}
	}

	&__up__0 {
		transform: translateY(100%);

		&--open {
			transform: translateY(0%);
		}
	}

	&__left {
		transform: translateX(100%);

		&--open {
			transform: translateX(0);
		}
	}
}
