@import '../../../assets/styles/variables';

.checkbox-button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	padding: 8px 24px;
	border-radius: 100px;
	gap: 4px;
	cursor: pointer;
	position: relative;
	width: max-content;

	&.success {
		background-color: $success;
	}

	&.warning {
		background-color: $primary;
	}

	.checkbox-icon {
		width: 12px;
		height: 12px;
		border: 1px solid white;
		background-color: transparent;
		border-radius: 1px;
		margin-right: 6px;
		display: inline-block;
	}

	.checked {
		background-color: white;
		content: url('../../../assets/imgs/dashboard-icons/icon_check.svg');
		background-size: cover;
	}

	.label {
		color: white;
		font-weight: 700;
		font-size: 14px;
		cursor: pointer;
	}

	&.warning .checkbox-icon {
		content: ' ';
		background-color: $primary;
		border: 1px solid white;
	}

	.btn-loader {
		position: absolute;
		top: calc(50% - 8px);
		left: calc(50% - 8px);
		border: 4px solid white;
		border-right-color: transparent;
	}
}
