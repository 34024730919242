@import '../../assets/styles/variables';

.reset-page {
	display: flex;
	justify-content: center;
	margin-top: 250px;

	&__form {
		max-width: 500px;
		margin: 50px auto;
		width: 500px;
		background-color: rgb(51, 51, 51);
		border-radius: 3px;
		padding: 30px 0 20px 0;

		&__header {
			text-align: left;
			height: 40px;
			background-color: #181818;
			margin-top: -30px;

			h5 {
				padding: 5px 0 0 10px;
				color: $primary;
			}
		}

		span {
			font-size: 12px;
			text-align: left;
			margin: -5px 97px 20px 0;
		}

		&__body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 30px;

			input {
				background-color: #4a4a4a;
				margin-bottom: 20px;
				height: 45px;
				border-radius: 3px;
				border: none;
				padding-left: 35px;
				padding-top: 5px;
				width: 85%;
				color: white;
				font-size: 16px;

				::placeholder {
					color: #4a4a4a !important;
				}
			}
		}

		&__icon {
			background-position: 4px 12px;
			background-repeat: no-repeat;
			background-size: 25px 20px;

			&--email {
				background-image: url('../../assets/imgs/noun_Email.svg');
			}

			&--password {
				background-image: url('../../assets/imgs/noun_Lock-01.svg');
			}
		}
	}

	&__text {
		font-size: 11px;
		width: 85%;
		margin: 0 auto;
	}

	&__forgot-password {
		display: flex;
		justify-content: space-between;
		width: 85%;
		font-size: 12px;

		a {
			color: #fff;
			text-decoration: none;
			margin-left: 5px;
			height: 20px;
		}

		&--token {
			justify-content: flex-end;
		}
	}

	figure {
		cursor: pointer;

		img {
			width: 45px;
		}
	}

	&__submit-reset {
		display: flex;
		justify-content: flex-end;
		width: 93%;
		font-size: 12px;
	}
}
