.invoice-attachment-header {
    display: flex;
    justify-content: space-between;
}

.invoice-attachment {
    color: black;
    float: left;
    margin: 10px 0px 10px 10px;
    width: 100%;
    max-height: 150px;
    overflow: auto;

    &__list {
        width: 100%;
        overflow-y: auto;
        overflow-x: auto;
    }

    .file-element {
        justify-content: space-between;
        display: flex;
        width: 100%;
        background-color: #eee;
        padding: 5px;
        margin: 5px 0px 5px 0px;
        text-align: center;
        vertical-align: middle;
        line-height: 40px; 
        span {
            display: flex;
            button {
                padding-top: 0px;
            }
        }
    }
} 