@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.custom-modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: white;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 500;

	&__window {
		min-height: 300px;
		position: relative;
		top: 35%;
		transform: translateY(-50%);

		&--share {
			top: 218px;

			@include respond(tab-portrait) {
				left: 15%;
			}

			@include respond(laptop) {
				top: 41%;
				left: 17%;
			}
		}
	}

	&__container {
		box-shadow: 0 0 38px -18px rgba(0, 0, 0, 0.75);
		border-radius: $border-radius;
		text-align: center;
		margin: 0 $padding;
		padding: $padding;
		display: flex;
		height: auto;
		flex-direction: column;
		background-color: white;

		@include respond(tab-portrait) {
			width: fit-content;
			margin: 0 auto;
		}

		&__button {
			padding: 12px 0;
			margin-bottom: 10px;
			border-radius: 8px;
			border: 1px solid black;
			font-weight: 100;
			background-color: white;
			color: black;

			&--cancel {
				background-color: $status-accepted;
				color: white;
				border: none;
			}

			&--active {
				background-color: $primary;
				color: white;
				border: none;
			}
		}
	}
}
button {
	width: auto;
}

.form-modal {
	background-color: $dark-gray;
	width: 50%;
	height: auto;
	padding: 0;
}