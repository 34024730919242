@import '../../assets/styles/variables';

.report-view {
	&__container {
		margin: 0 40px;
	}

	.date-range-filter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__dropdown {
		height: 30px;
		width: 100px;
		background: #495057;
		display: flex;
		flex-direction: column;
		border-radius: 3px;

		&-item {
			color: #fff;
			text-decoration: none;
			padding: 8px;
			background-color: #495057;

			&:hover {
				color: #fff;
				background-color: #333;
			}
		}
	}

	.title {
		color: orange;
		font-size: 90px;
		font-family: Lato, sans-serif;
		font-weight: 300;
	}

	.btn {
		color: white !important;
		border-radius: 0 !important;
		height: calc(1.5em + 0.75rem + 2px);
	}

	.btn-info {
		background: #00c4b3;
		border-color: #00c4b3;
		width: 100px;
		height: calc(1.5em + 0.75rem + 2px);
	}

	.btn-info-expanded {
		width: 150px;
	}

	.btn-primary {
		color: #fff;
		background-color: $primary;
		border-color: $primary;

		&:focus,
		&:hover {
			background-color: $primary;
			border-color: $primary;
		}
	}

	.input-group {
		border-bottom: 0;
	}

	.input-group-append {
		margin-left: -1px !important;
	}

	.input_group > .input-group-append:not(:last-child) > .input-group-text {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.input-group-text {
		display: flex !important;
		align-items: center !important;
		padding: 0.375rem 0.75rem !important;
		margin-bottom: 0 !important;
		font-size: 1rem !important;
		font-weight: 400 !important;
		line-height: 1.5 !important;
		color: #495057 !important;
		text-align: center !important;
		white-space: nowrap !important;
		background-color: #e9ecef !important;
		border: 1px solid #ced4da !important;
	}

	.input-group-prepend .input-group-text {
		width: 45px;
		background-color: #e9ecef !important;
	}

	.form-control {
		width: 16%;
		display: block;
		height: calc(1.5em + 0.75rem + 2px);
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-clip: padding-box;
		border-radius: 0;
	}

	th {
		font-size: 12px;
	}
}
