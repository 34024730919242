@import './assets/styles/variables';
@import './assets/styles/fonts';

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	font-size: 14px;
	font-family: 'Lato', sans-serif;
	overflow-x: hidden;
	background-color: #282828 !important;
	color: #fff !important;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

button {
	display: block;
	border-radius: 0;
	border: none;
	box-shadow: none;
	text-align: center;
	text-decoration: none;
	height: 40px;
	width: 40px;
}

.btn--primary {
	background-color: $primary;
	border-color: $primary;
}

.btn--success {
	background-color: #70d44b;
	border-color: #70d44b;
	color: #fff;
}

.btn-wide {
	min-width: 260px;
	font-weight: 700;
}

.btn--round {
	border-radius: 90px;
	padding: 4px 10px;
	min-width: 34px;
}

::-webkit-scrollbar {
	width: 12px;
	background-color: $dark-gray;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: $dark-gray;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: $primary;
}
::-webkit-scrollbar-thumb {
	background-color: $secondary;
}

.btn-loader {
	margin: 0 auto;
	width: 16px;
	height: 16px;
	border: 4px solid #eee;
	border-right-color: transparent;
	border-radius: 50%;
	animation: loader-rotate 1s linear infinite;
}

.visibility-hidden {
	visibility: hidden;
}
