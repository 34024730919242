@import '../../../../assets/styles/variables';
@import '../../LoginOption.scss';
.verify-code-form {
	@extend .login-form;
	font-family: 'Roboto', sans-serif;
	&__back-btn {
		position: absolute;
		top: 20px;
		left: 20px;
		color: #fff;
		text-decoration: none;
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 8px;
		&:hover {
			color: #fff;
			text-decoration: none;
		}
		i {
			font-size: 16px;
		}
	}
	&__header {
		@extend .login-form__header;
	}

	&__body {
		@extend .login-form__body;
		input {
			text-align: center;

			padding-left: 0 !important;
		}
	}

	&__icon {
		@extend .login-form__icon;

		&[type='number'] {
			color: #b7b7b7;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}

	&__resend {
		width: 85%;
		text-align: center;
		margin: 15px 0;

		&-link {
			font-family: $connect-font;
			color: #ffff;
			cursor: pointer;
			font-size: 14px;
			text-decoration: none;
			font-weight: 700;
			line-height: 16px;

			&.disabled {
				color: #282828;
				cursor: not-allowed;
				pointer-events: none;
			}

			&:hover {
				color: #f87000;
				text-decoration: none;
				opacity: 0.8;
			}
		}
	}

	&__countdown {
		color: #f87000;
		font-size: 14px;
		font-weight: 700;
		margin-left: 5px;
	}
	&__footer {
		@extend .login-form__footer;
	}

	&__btn {
		@extend .login-form__btn;
		&--loading {
			@extend .login-form__btn--loading;
		}
	}
	&__remember-device {
		display: flex;
		font-size: 14px;
		gap: 8px;
	}
}
