@import '../../assets/styles/variables';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

.service-view {
	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #181818;
		color: white;
	}

	&__po-number {
		display: flex;
		align-items: center;
		margin-left: 20px;

		i {
			color: white;
			font-size: 20px;
		}

		p {
			font-size: 20px;
			margin: 0 16px;
			font-weight: 700;
		}
	}

	// ----- react-image-gallery  -----
	.image-gallery {
		flex-direction: column;
		align-items: center;
	}

	.image-gallery-thumbnails {
		overflow: inherit;
		margin-top: 10px;
	}

	.image-gallery-thumbnail {
		height: auto;
	}

	.image-gallery-slide img {
		width: 100%;
		height: 300px;
	}
	// ----- end react-image-gallery  -----

	&__service-status {
		background-color: #74d00b;
		border-color: #74d00b;
		width: 250px;
		text-align: center;
		font-weight: 700;
		font-size: 18px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
	}

	&__label {
		color: $primary;
	}

	&__distance-format {
		width: auto !important;
		text-align: right !important;
		padding-top: 28px !important;
		color: $primary !important;
	}

	&__icon {
		height: 35px;
		width: auto;
	}

	&__vr-divider {
		border: none;
		border-right: 3px black solid;
	}

	&__inspections {
		display: flex;
		margin-top: 10px;
	}

	&__map-no-visible {
		background-color: #58595b;
		height: 300px;
		width: 100%;
		padding-top: 150px;

		p {
			color: #fc521e;
			text-align: center;
			font-size: 16px;
		}
	}

	&__notes {
		max-height: 170px;
		margin-bottom: 30px;
		overflow-y: auto;

		&-subtitle {
			font-size: 17px;
		}

		&-msg {
			font-size: 14px;
			white-space: break-spaces;
		}

		&-textbox {
			display: flex;

			&-area {
				flex-grow: 1;
			}
		}

		&-submit {
			align-self: flex-end;
		}
	}

	&__timestamps {
		display: flex;
		flex-direction: column;
		width: 100%;

		&-title {
			font-size: 14px;
			text-align: left;
		}

		&-header {
			display: flex;
			justify-content: space-between;
		}

		&-stamps {
			display: flex;
			width: 165px;
			justify-content: space-between;
		}

		&-time {
			font-size: 17px;
			font-weight: 500;
		}

		&-row {
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;
			text-align: center;
		}

		&__add {
			cursor: pointer;
			border-radius: 50%;
			width: 40px;
			padding: 4px;
			height: 40px;

			img {
				padding: 5px;
			}
		}

		&__selection {
			background: transparent;
			border-color: transparent;
			color: #fff;
			font-size: 17px;
			font-weight: 700;
			width: 100px;
		}
	}

	&__pdf-report {
		display: flex;
		align-content: center;
		align-items: center;

		span {
			font-size: 12px;
			margin-top: 20px;
		}
	}
}

.switch-container {
	display: flex;
	align-items: center;
	margin-left: 10px;
	margin-top: 5px;

	span {
		font-size: 12px;
		margin-top: 4px;
		margin-left: 4px;
	}
}

.distance-line {
	border-bottom: 3px black solid;
	height: 60px;
}

.distance-column {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #aaa;
	font-size: 14px;
	outline: none;
	cursor: default;
}

.distance-input {
	margin-left: 10px;
	width: 60px;
	position: relative;
	background-color: transparent;
	border: none;
	color: white;
	align-items: right;
	outline: none;
	cursor: default;
}

.map-header {
	background-color: $dark-gray !important;
	padding: 5px 14px;
	label {
		margin: auto;
	}
	width: 100%;
}

.no-content {
	height: 28px;
}

.tab-container {
	display: flex;
}

.btn-round {
	flex-grow: 1;
	border-radius: 25px !important;
	padding: 12px;
	font-size: 12px;
	font-weight: 700;
	margin: 0 4px;
	width: 40px;
}

.btn-round.active {
	background-color: $primary !important;
	border-color: $primary !important;
	color: #fff !important;
}
.btn {
	border-radius: 0;
	box-shadow: none;
}

.btn-outline-light {
	border-color: #e8e8e8 !important;
}

.btn-circle {
	border-radius: 90px !important;
	height: 40px;
	padding: 4px;
	width: 40px;
	display: flex !important;
	justify-content: center !important;
	img {
		padding: 5px;
	}
}

.btn-success {
	background-color: #70d44b !important;
	border-color: #70d44b !important;
}

.nothing-found-alert {
	color: #fc521e;
	text-align: center;
	font-size: 18px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.bottom-line {
	border: #000 2px solid;
	border-top: none;
	border-left: none;
	border-right: none;
	margin-bottom: 4px;
}

.text-bold {
	font-size: 17px;
	font-weight: 700;
}

// Overrides for Bootstrap
// had to use them since
// Eos-web uses them heavily
// ... which makes it a nightmare to recreate
// the layout and style by hand :(

.divider {
	border-width: 5px;
	border-color: $primary !important;
	background-color: $primary !important;
	margin-top: 7px;
	margin-bottom: 10px;
	height: 5px !important;
}
.form-control {
	outline: none;
	cursor: default;
}
.form-control-dark,
.form-control-dark:focus {
	border-radius: 0;
	background-color: $dark-gray;
	border: 1px solid $dark-gray;
	color: white !important;
	border: 0 !important;
	box-shadow: none !important;
	cursor: default !important;
}

.form-control-dark:read-only {
	background-color: transparent !important;
	border-color: transparent !important;
	font-weight: 700;
	outline: none;
	cursor: default;
}
.form-control-dark.transparent {
	background-color: transparent !important;
	border-color: transparent !important;
	outline: none;
	cursor: default;
}
.form-control-dark.form-control-outline {
	background-color: transparent !important;
	border: 3px $dark-gray solid;
	font-size: 14px;
	font-weight: 500;
}

.input-group {
	border: none;
	border-bottom: 3px black solid;
	padding-bottom: 3px;
	background-color: transparent !important;
	outline: none;
	cursor: default;
	height: 100%;
}
.input-group-prepend {
	display: flex;

	.input-group-text.text-success {
		color: $success !important;
	}

	.input-group-text {
		border-right: 3px black solid !important;
		text-align: start;
		width: 105px;
		border-radius: 0 !important;
		border: none;
		font-weight: 700;
		font-size: 15px;
		white-space: normal;
		border: none;
		background-color: transparent !important;
		color: #aaa !important;
		cursor: default !important;
		&__edit-mode {
			color: $primary !important;
		}
	}
	.input-group-text.lg {
		width: 120px;
	}
	.input-group-text.xlg {
		width: 160px;
	}
}

.sm-gutters {
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12 {
		padding-right: 2px !important;
		padding-left: 2px !important;
	}

	margin-right: 0px;
	margin-left: 0px;
}

.image-gallery {
	width: 100%;
	height: auto;
}

// Classes for overriding react-image-gallery styles
.image-gallery-slide img {
	width: 100%;
	height: auto;
	max-height: 80vh;
	object-fit: cover;
	overflow: hidden;
	object-position: center center;
}

.fullscreen .image-gallery-slide img {
	max-height: 100vh;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
	cursor: pointer;
	text-align: center;
	transition: transform 0.45s ease-out;
	width: 30px;
}

#service-desk {
	margin-left: 10px;
	width: 80px;
	height: 40px;
	border-radius: 5px;
	color: white;
	background-color: #353535;
	border: 0;
	outline: none;
	option {
		background-color: #353535;
		color: white;
	}
}

.switch-container {
	.label-service {
		margin-left: 80px;
	}
}

.icon-pointer {
	cursor: pointer;
}
.files-list {
	margin-top: 1.5rem;
	cursor: pointer;
	.single-file {
		display: flex;
		padding: 0.5rem;
		justify-content: space-between;
		align-items: center;
		border: dashed 1px #979797;
		margin-bottom: 1rem;
		cursor: pointer;
		img.delete {
			margin-left: 0.5rem;
			cursor: pointer;
			align-self: flex-end;
		}

		display: flex;
		flex-grow: 1;

		.name {
			font-size: 14px;
			font-weight: 500;
			margin: 0;
		}

		.size {
			font-size: 12px;
			font-weight: 500;
			color: #fff;
			margin: 0;
			margin-bottom: 0.25rem;
		}

		.info {
			width: 100%;
		}
	}
}

.flex-container {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.custom-input-group {
	box-sizing: border-box;
	border-radius: 5.32151px;
	display: flex;
	align-self: center;
	padding-left: 10px;
	padding-right: 10px;
	margin: auto !important;
	height: 70%;
	background: content-box;
	color: white;
}

.p-inputtext,
input[type='text'] {
	font-size: 12px;
}

.currency-input {
	height: 40px;
	margin: auto;

	&__edit-mode {
		input {
			color: #77c926;
			border-color: #77c926;
		}
	}
}

.edit-mode.p-dropdown {
	color: $success;
	border-color: $success;
}

.edit-mode .p-dropdown-label.p-inputtext.p-placeholder {
	color: $success;
}

.edit-mode .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
	color: $success;
}

.prepaid-switch {
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.billed-distance-a {
	cursor: pointer;
	position: relative;
	top: 25%;
	left: 56%;
	font-weight: bold;
	text-decoration: none;
	color: white;
}

.billed-distance-tooltip {
	z-index: 999;
	background-color: #f2f2f2;
	color: black;
}

.provider-quotes-button {
	margin-left: 1rem;
	margin-bottom: 1.5rem;
}