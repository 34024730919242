@import '../../assets/styles/variables';

.report-table {
	font-size: 12px;
	display: block;
	width: 100%;
	max-width: -moz-fit-content;
	max-width: fit-content;
	overflow-x: auto;
	white-space: nowrap;

	&__header {
		border-style: hidden;
		font-size: 14px;
		color: white;
		background-color: #282828;
		text-align: center;
	}

	tr:nth-child(odd) {
		background-color: rgba(255, 255, 255, 0.05);
	}

	th,
	td,
	tr {
		width: 75px !important;
		text-align: center;
		vertical-align: middle;
		padding-bottom: 15px;
		padding: 0.75rem;
	}
}
